<template>
<div class="suit-items-root">
    <div class="text">Suit Items</div>
    <div class="search_bar">
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Search..."
          />
    </div>
    <div class="items-table">
        <b-table
        class="table-position"
        :items="getSelectedMerchants"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found">

        </b-table>
    </div>
</div>
</template>


<script>
import {BTable, BFormInput, BForm} from 'bootstrap-vue';

export default {
    components:{
        BFormInput,
        BTable,
        BForm
    },
    data() {
        return {
           tableColumns: [{key: ''},{key: ''}]
        }
    },
    computed: {

    },
    methods() {
        
    }
}
</script>

<style scoped>
.items-table{
    margin-top: 2rem;
}
</style>